import React from 'react';
import {Outlet} from "react-router-dom";
import Base from "./Base";
import Header from "./Header";

function Home() {
    return (
        <Base>
            <Header/>
            <main>
                <Outlet/>
            </main>
        </Base>
    );
}

export default Home;
