import React from "react";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();

    return (
        <div className={"container mx-auto grid lg:grid-cols-2 gap-10 rounded-xl p-10 mt-10 items-center"}>
            <img src="/background-sm.png" alt="background" className={""}/>
            <div className={"grid gap-5"}>
                <h1 className={"text-5xl"}>Oops!</h1>
                {
                    isRouteErrorResponse(error)
                        ? (
                            <>
                                <h2>{error.status}</h2>
                                <p className={"text-xl"}>{error.statusText}</p>
                                {error.data?.message && <p className={"text-xl"}>{error.data.message}</p>}
                            </>
                        )
                        : <p>There was a problem with your request :(</p>
                }
            </div>
        </div>
    );
}