import {Form, useNavigation,} from "react-router-dom";
import React, {KeyboardEventHandler} from "react";

interface Props {
    ids: string;
}

function MarketListSearch(props: Props) {

    const navigation = useNavigation();

    const handleIdsSearchInput: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === ',') {
            e.preventDefault();
            e.currentTarget.value += ', ';
            return;
        }
        if (e.currentTarget.value.substring(e.currentTarget.value.length - 1) === ',' ||
            e.currentTarget.value.substring(e.currentTarget.value.length - 1) === ' ') {
            if (e.key === " " || e.key === ",") {
                e.preventDefault();
                return;
            }
        }
        if (e.code === 'Space') {
            e.preventDefault();
            e.currentTarget.value += ', ';
        }
    }

    return (
        <Form role={"search"} className={"flex items-center"}>
            <input type="text" placeholder={"Search Ex: Bitcoin"} className="input input-bordered w-full max-w-xl my-5"
                   name={'ids'} onKeyDown={handleIdsSearchInput} defaultValue={props.ids}/>
            {
                navigation.state === "loading" &&
                <button className="btn btn-square loading ml-2" type={"button"}></button>
            }
        </Form>
    );
}

export default MarketListSearch;