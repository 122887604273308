import React, {useContext} from 'react';
import ThemeContext from "../../ThemeContext";
import {toggleTheme} from "../../utils/theme";
import SunIcon from "../icons/SunIcon";
import MoonIcon from "../icons/MoonIcon";

function ChangeThemeButton() {

    const themeContext = useContext(ThemeContext);

    return (
        <div onClick={toggleTheme.bind(null, themeContext)} className={"btn"}>
            {
                themeContext.theme === 'dark'
                    ? <SunIcon/>
                    : <MoonIcon/>
            }
        </div>
    );
}

export default ChangeThemeButton;
