import {useState} from 'react';

export type RecentSearchesType = {
    id: string;
    image: string;
    name: string;
    price: string;
}

function useRecentSearches() {

    const [recentSearches, setRecentSearches] = useState<RecentSearchesType[]>(() => {
        const item = window.localStorage.getItem('recent-searches');
        return item ? JSON.parse(item) : [];
    })

    function add(record: RecentSearchesType) {
        if (!recentSearches.find(it => it.id === record.id)) {

            if (recentSearches.length >= 3) {
                recentSearches.shift();
            }

            const newRecentSearches = [
                ...recentSearches,
                {
                    id: record.id,
                    image: record.image,
                    name: record.name,
                    price: record.price,
                }
            ];
            localStorage.setItem('recent-searches', JSON.stringify(newRecentSearches));
            setRecentSearches(newRecentSearches)
        }
    }

    return {
        recentSearches,
        addRecentSearches: add
    };
}

export default useRecentSearches;