import ErrorPage from "../pages/ErrorPage";
import {createBrowserRouter} from "react-router-dom";
import Home from "../pages/Home";
import React from "react";
import Layout from "../components/layout/Layout";
import SingleCoin from "../pages/Coins/SingleCoin";
import CoinsMarket from "../pages/Coins/Markets";
import coinLoader from "./loaders/coinLoader";
import marketsLoader from "./loaders/marketsLoader";

const index = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/coins",
        element: <Layout/>,
        children: [
            {
                index: true,
                loader: marketsLoader,
                element: <CoinsMarket/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: "markets",
                loader: marketsLoader,
                element: <CoinsMarket/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: ":coinId",
                loader: coinLoader,
                element: <SingleCoin/>,
                errorElement: <ErrorPage/>,
            },
        ],
    }
]);

export default index;