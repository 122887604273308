import React from 'react';
import MarketsList from "../../components/markets/List";

function Markets() {

    return (
        <>
            <div className="hero h-96 bg-banner">
                <div className="hero-overlay bg-opacity-60"></div>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <h1 className="mb-5 text-5xl">Search Coin</h1>
                        <p className="mb-5">Get information from here</p>
                    </div>
                </div>
            </div>

            <div className="container mx-auto py-10">
                <h2 className={"text-center text-xl"}>Cryptocurrency Prices by Market Cap</h2>

                <MarketsList/>
            </div>

        </>
    );
}

export default Markets;
