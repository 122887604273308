import {Link} from "react-router-dom";
import {getPriceChangeColorClassName} from "../../utils/helpers";
import React from "react";

export type MarketType = {
    name: string;
    symbol: string;
    id: string;
    image: string;
    current_price: string;
    market_cap: string;
    price_change_percentage_24h: string;
}

interface Props {
    market: MarketType;
}

export default function Item({market}: Props) {

    return (
        <tr>
            <th className={"px-2 py-5"}>
                <Link to={`/coins/${market.id}`}>
                    <div className="flex items-center space-x-3">
                        <div className="avatar">
                            <div className="mask mask-squircle w-12 h-12">
                                <img src={market.image} alt={`${market.name} thumbnail`}/>
                            </div>
                        </div>
                        <div>
                            <div className="font-bold uppercase">{market.symbol}</div>
                            <div className="text-sm opacity-50">{market.name}</div>
                        </div>
                    </div>
                </Link>
            </th>
            <td>${market.current_price}</td>
            <td className={`${getPriceChangeColorClassName(+market.price_change_percentage_24h)}`}>{market.price_change_percentage_24h}%</td>
            <td>${market.market_cap}</td>
        </tr>
    )
}