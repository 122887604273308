import React from 'react';
import ChangeThemeButton from "../buttons/ChangeThemeButton";
import {Link} from "react-router-dom";

function Home() {
    return (
        <header className={"navbar bg-base-100 bg-opacity-60 py-5 shadow-xl sticky top-0 z-40 backdrop-blur"}>
            <div className="container mx-auto flex justify-between">
                <Link to={'/'}>
                    <h2 className={"btn btn-ghost normal-case text-xl"}>Test Project</h2>
                </Link>
                <ChangeThemeButton/>
            </div>
        </header>
    );
}

export default Home;
