import {Link} from "react-router-dom";
import React from "react";
import useRecentSearches from "../../hooks/useRecentSearches";

export default function RecentSearches() {

    const {recentSearches} = useRecentSearches();

    return (
        <div className={"grid lg:grid-cols-3 gap-5 self-center w-full"}>
            {recentSearches.map(recentSearch => (
                <Link to={`/coins/${recentSearch.id}`} className={"btn btn-lg glass btn-accent"}
                      key={recentSearch.id}>
                    <div className="flex items-center justify-between space-x-2">
                        <div className="avatar">
                            <div className="mask mask-squircle w-12 h-12">
                                <img src={recentSearch.image} alt={`${recentSearch.name} thumbnail`}/>
                            </div>
                        </div>
                        <div>
                            <div className="font-bold uppercase text-sm">${recentSearch.price}</div>
                            <div className="text-xs opacity-50">{recentSearch.name}</div>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    )
}