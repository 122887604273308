import React, {useEffect} from 'react';
import {useLoaderData} from "react-router-dom";
import useRecentSearches from "../../hooks/useRecentSearches";

type coinDataType = {
    id: string;
    name: string;
    image: {
        small: string;
        large: string;
    };
    price: string;
    market_data: {
        current_price: { usd: string };
        market_cap: { usd: string };
    };
    market_cap_rank: string;
    description: { en: string };
}

function SingleCoin() {

    const {coin: coinData} = useLoaderData() as { coin: coinDataType };
    const {addRecentSearches} = useRecentSearches();

    // Add result to localStorage
    useEffect(() => {
        addRecentSearches({
            id: coinData.id,
            name: coinData.name,
            image: coinData.image.small,
            price: coinData.market_data.current_price.usd,
        })
    }, [addRecentSearches, coinData.id, coinData.image.small, coinData.market_data.current_price.usd, coinData.name])

    return (
        <div className={"container mx-auto py-10"}>

            <div className="p-5 lg:p-10 bg-base-200 rounded-xl">
                <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-5 space-y-10 lg:space-y-0">

                    {/* Coin Logo */}
                    <img src={coinData.image.large} alt={`${coinData.name}`}
                         className="max-w-sm mask mask-squircle shadow-2xl hidden lg:block"/>

                    <div>
                        {/* Coin Logo & Name */}
                        <div className={"flex items-center pb-10"}>
                            <img src={coinData.image.small} alt={coinData.name}
                                 className="rounded-full shadow-2xl lg:hidden mr-2"/>
                            <h1 className="text-3xl lg:text-5xl font-bold">{coinData.name}</h1>
                        </div>


                        {/* Stats */}
                        <div className="stats stats-vertical lg:stats-horizontal shadow w-full">

                            <div className="stat">
                                <div className="stat-title">Market Cap</div>
                                <div className="stat-value text-2xl">${coinData.market_data.market_cap.usd}</div>
                            </div>

                            <div className="stat">
                                <div className="stat-title">Market Rank</div>
                                <div className="stat-value">{coinData.market_cap_rank}</div>
                            </div>

                            <div className="stat">
                                <div className="stat-title">Price</div>
                                <div className="stat-value">${coinData.market_data.current_price.usd}</div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* Coin Description */}
            <div className={"bg-base-200 rounded-xl mt-5 p-5 lg:p-10 lg:text-xl"}>
                <p className={"text-3xl mb-5 lg:mb-10 font-bold"}>Description</p>
                <p className={"prose leading-relaxed"} dangerouslySetInnerHTML={{
                    __html: coinData.description.en
                }
                }/>
            </div>
        </div>
    );
}

export default SingleCoin;
