import React from 'react';
import Base from "../components/layout/Base";
import {Link} from "react-router-dom";
import ChangeThemeButton from "../components/buttons/ChangeThemeButton";
import RecentSearches from "../components/partials/RecentSearches";

function Home() {
    return (
        <Base>
            <div className={"lg:h-screen lg:grid grid-cols-2 bg-white dark:bg-[#0f002b] py-16 lg:py-0"}>

                {/* Home Page Info */}
                <div className="grid place-content-center">
                    <h1 className={"text-5xl lg:text-8xl"}>Search &<br/>Buy <span
                        className={"text-warning"}>Crypto</span></h1>
                    <div className={"my-16 text-2xl"}>
                        <p>Mohaymen ICT</p>
                        <p>Test Project</p>
                    </div>
                    <div>
                        <Link to={"/coins/markets"}
                              className={"btn btn-warning"}>Search More</Link>
                    </div>
                </div>

                <div className={"grid p-5 justify-items-end"}>
                    {/* Change Theme Button */}
                    <div className={"fixed lg:relative top-3 right-3"}>
                        <ChangeThemeButton/>
                    </div>

                    {/* Floating Background */}
                    <img src="/background-sm.png" alt="background"
                         className={"animate-float my-20 lg:my-3 lg:max-w-xl mx-auto"}/>

                    {/* Recent Searches */}
                    <RecentSearches/>
                </div>

            </div>
        </Base>
    );
}

export default Home;
