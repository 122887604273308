import React, {PropsWithChildren, useState} from 'react';
import ThemeContext, {Theme} from "../../ThemeContext";

interface Props extends PropsWithChildren {

}

function Home(props: Props) {

    const [theme, setTheme] = useState<Theme>(localStorage.getItem('theme') as Theme || "dark");

    return (
        <ThemeContext.Provider value={{theme, setTheme}}>
            <div className={`min-h-full`} data-theme={theme}>
                {props.children}
            </div>
        </ThemeContext.Provider>
    );
}

export default Home;
