import React, {Dispatch, SetStateAction} from "react";

export type Theme = "light" | "dark";
export type ThemeContextType = { theme: Theme, setTheme: Dispatch<SetStateAction<Theme>> };

const ThemeContext = React.createContext<ThemeContextType>({
    theme: "dark",
    setTheme: () => {
    }
})
export default ThemeContext