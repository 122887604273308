import {LoaderFunctionArgs} from "react-router-dom";
import {getCoinById} from "../../api";

export default async function coinLoader(args: LoaderFunctionArgs) {
    const {params} = args;
    try {
        const res = await getCoinById(params.coinId as string);

        const coin = await res.json();
        if (!coin) {
            throw new Response("", {
                status: 404,
                statusText: `Coin \`{${params.coinId}}\` Not Found`,
            });
        }

        return {coin};
    } catch (e) {
        throw new Response("", {
            status: 429,
            statusText: `Too Many Requests! Please Wait.`,
        });
    }
}