import {ThemeContextType} from "../ThemeContext";

export const toggleTheme = (context: ThemeContextType) => {
    if (context.theme === 'light') {
        context.setTheme('dark');
        localStorage.setItem('theme', 'dark');
    } else {
        context.setTheme('light');
        localStorage.setItem('theme', 'light');
    }
}