import React from 'react';
import MarketListSearch from "./MarketListSearch";
import Item, {MarketType} from "./Item";
import {useLoaderData} from "react-router-dom";

interface LoaderData {
    markets: MarketType[];
    ids: string;
}

function List() {

    const {markets, ids} = useLoaderData() as LoaderData;

    return (
        <div className={"mt-10 bg-base-200 rounded-xl p-5"}>
            <MarketListSearch ids={ids}/>

            {
                markets.length
                    ? <div className="overflow-x-auto w-full mt-5">
                        <table className="table table-zebra w-full">
                            <thead>
                            <tr>
                                <th>Coin</th>
                                <th>Price</th>
                                <th>24H Change</th>
                                <th>Market Cap</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                markets.map(market => <Item market={market} key={market.id}/>)
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                                <th>Coin</th>
                                <th>Price</th>
                                <th>24H Change</th>
                                <th>Market Cap</th>
                            </tr>
                            </tfoot>

                        </table>
                    </div>
                    : <p className={"text-center mt-5 text-red-400"}>Sorry, Nothing found with these coin IDs</p>
            }
        </div>
    );
}

export default List;
