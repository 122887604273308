import {LoaderFunctionArgs} from "react-router-dom";
import {getMarkets} from "../../api";

export default async function marketsLoader(args: LoaderFunctionArgs) {
    const url = new URL(args.request.url);

    const ids = url.searchParams.get("ids")
        ?.trim()
        .split(',')
        .map(it => it.trim())
        .filter(it => it.length)
        .join(',');

    try {
        const res = await getMarkets(ids as string);
        const markets = await res.json();

        return {markets, ids};
    } catch (e) {
        throw new Response("", {
            status: 429,
            statusText: `Too Many Requests! Please Wait.`,
        });
    }
}